<template>
  <TabView v-if="application" lazy v-model:activeIndex="activeTab">
    <TabPanel>
      <template #header>
        <span
          ><i class="fas fa-fw fa-paper-plane"></i> Application -
          {{ application.applicationId }}</span
        >
      </template>

      <!--Content-->
      <ContextMenu ref="menu" :model="menuOptions" />
      <div @contextmenu="applicationMenu">
        <Card>
          <template #title>
            <span v-html="profileStatus"></span> {{ application.applicant.company }}
          </template>

          <template #content>
            <cfs-details
              v-if="application.applicationType === 'cg'"
              :application="application"
              :statusIcon="statusIcon"
            />
            <co-details
              v-if="application.applicationType === 'co'"
              :application="application"
              :statusIcon="statusIcon"
            />

            <delivery-method :application="application"></delivery-method>

            
          </template>
        </Card>
      </div>
    </TabPanel>
    <TabPanel>
      <!--profile Tab -->
      <template #header>
        <span><i class="fas fa-fw fa-city"></i> Company Profile</span>
      </template>
      <company-information :profile="profile.applicant"></company-information>
    </TabPanel>

    <TabPanel>
      <template #header>
        <span><i class="fas fa-file-alt"></i> Documents</span>
      </template>
      <upload-documents :id="id" />
    </TabPanel>
  </TabView>

  <Dialog
    v-model:visible="timelineModal"
    :cloasble="false"
    :dismissableMask="true"
    :showHeader="true"
    :modal="true"
    header="Timeline"
    
  >
  

    <div class="p-5">
      <time-line :events="application.messages" />
    </div>
  </Dialog>

  <Dialog
    v-model:visible="documentModal"
    :cloasble="false"
    :dismissableMask="true"
    :showHeader="false"
    :modal="true"
    :maximizable="true"
  >
    <object
      :width="documentWidth"
      :height="documentHeight"
      type="application/pdf"
      :data="document"
    ></object>
  </Dialog>

  <Dialog
    v-model:visible="billingModal"
    header="Billing Information"
    :dismissableMask="true"
    :modal="true"
    :maximizable="false"
  >

    <billing-details :application="application"></billing-details>

  </Dialog>
</template>

<script>
import CfsDetails from "@/views/Application/CfsDetails.vue";
import CoDetails from "@/views/Application/CoDetails.vue";
import TimeLine from "@/views/Application/TimeLine.vue";
import UploadDocuments from "@/views/Application/UploadDocuments.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ContextMenu from "primevue/contextmenu";
import Dialog from "primevue/dialog";
import CompanyInformation from "./CompanyInformation.vue";
import BillingDetails from "./BillingDetails.vue";
import DeliveryMethod from "./DeliveryMethod.vue";
export default {
  name: "Application Details",
  components: {
    TabView,
    TabPanel,
    ContextMenu,
    Dialog,
    CfsDetails,
    CoDetails,
    BillingDetails,
    CompanyInformation,
    UploadDocuments,
    DeliveryMethod,
    TimeLine
  },
  props: ["id"],
  data() {
    return {
      timelineModal: false,
      billingModal: false,

      documentModal: false,
      document: false, //file to display in document modal
      documentWidth: 0,
      documentHeight: 0,

      activeTab: 0,
      application: null,
      profile: {},
      menuOptions: [
        {
          label: "Preview Certificate",
          icon: "fas fa-fw fa-file-certificate",
          visible: () => this.application.status === "pending"
        },
        {
          label: "Approve Certificate",
          icon: "fas fa-fw fa-check-circle",
          visible: () => this.application.status === "pending"
        },
        {
          label: "Decline Certificate",
          icon: "fas fa-fw fa-times-circle",
          visible: () => this.application.status === "pending"
        },

        {
          label: "View Certificate",
          icon: "fas fa-fw fa-file-certificate",
          visible: () => this.application.status === "approved",
          items: []
        },
        {
          separator: true
        },
        {
          label: "Supporting Documents",
          icon: "fas fa-fw fa-layer-group",
          items: []
        },
        {
          label: "Invoice",
          icon: "fas fa-fw fa-receipt",
          visible: () => this.application.status === "approved"
        },
        {
          label: "Billing Details",
          icon: "fas fa-fw fa-file-invoice-dollar",
          visible: () =>
            this.application.status === "approved" || this.application.status === "pending",
          command: () => {
            this.billingModal = true;
          }
        },
        {
          label: "Timeline",
          icon: "fas fa-fw fa-stream",
          command: () => {
            this.timelineModal = true;
          }
        }
      ]
    };
  }, // data()

  created() {
    console.log(this.$dayjs)
    this.getData();
  },

  methods: {
    getData() {
      this.axios.get(`/application/${this.id}`).then(response => {
        this.application = response.data;
        (this.profile = response.data), (this.activeTab = 0);

        //update certificates
        this.menuOptions[3].items.length = 0;
        this.application.certificates.forEach(document => {
          this.menuOptions[3].items.push({
            label: document.name,
            command: () => {
              this.viewDocument(document);
            }
          });
        });

        //update attachments
        this.menuOptions[5].items.length = 0;
        this.application.documents.forEach(document => {
          this.menuOptions[5].items.push({
            label: document.name,
            command: () => {
              this.viewDocument(document);
            }
          });
        });
      });
    },

    applicationMenu(event) {
      this.$refs.menu.show(event);
    }, //applicationMenu()

    viewDocument(document) {
      this.document = "https://docs.ftgs.us" + document.path + document.fileName;
      this.documentModal = true;
      this.documentWidth = Math.floor(window.innerWidth * 0.75);
      this.documentHeight = Math.floor(window.innerHeight * 0.75);
    } //viewDocument()
  },

  computed: {
    statusIcon() {
      if (this.application.status === "approved") return "fas fa-check-circle el-status-approved";
      else if (this.application.status === "declined")
        return "fas fa-times-circle el-status-declined";
      else if (this.application.status === "pending")
        return "fas fa-hourglass-half el-status-pending";
      else if (this.application.status === "draft") return "fas fa-file-edit el-status-draft";
      else return "";
    }, //statusIcon()

    profileStatus() {
      if (Number(this.application.applicant.isApproved))
        return "<i class='fas fa-check-circle el-status-approved'></i>";
      if (Number(this.application.applicant.isdeclined))
        return "<i class='fas fa-times-circle el-status-approved'></i>";
      return "<i class='fas fa-hourglass-half el-status-approved'></i>";
    }, //profileStatus()

  }, // methods()
  watch: {
    id() {
      this.getData();
    }
  } // watch()
};
</script>

<style></style>
