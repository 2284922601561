<template>
  <Timeline :value="timeline" class="customized-timeline">
    <template #marker="slotProps">
      <span class="el-timeline-marker" :style="{ backgroundColor: slotProps.item.color }">
        <i :class="slotProps.item.icon"></i>
      </span>
    </template>

    <template #opposite="slotProps">
      <small class="p-text-secondary">{{ slotProps.item.date }}</small>
    </template>

    <template #content="slotProps">
      <span class="status">{{ slotProps.item.status }}</span
      ><br />
      <span class="note">
        {{ slotProps.item.notes }}
        <span v-if="slotProps.item.user"> <strong>{{ slotProps.item.user }}</strong></span>
      </span>
    </template>

    <template #connector>
      <div class="p-timeline-event-connector"></div>
    </template>
  </Timeline>
</template>

<script>
import Timeline from "primevue/timeline";

export default {
  components: {
    Timeline
  },
  props: ["events"],
  created() {
    this.events.forEach(event => {
      let user = event.firstname ? ` - ${event.firstname} ${event.lastname}` : "";

      if (event.status === "submitted")
        this.timeline.push({
          status: "Submitted",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-paper-plane",
          color: "#258cc7"
        });
      else if (event.status === "declined")
        this.timeline.push({
          status: "Declined",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-times",
          color: "#942509"
        });
      else if (event.status === "void_payment")
        this.timeline.push({
          status: "Payment Voided",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-hand-holding-usd",
          color: "#552020"
        });
      else if (event.status === "payment_charged")
        this.timeline.push({
          status: "Payment Charged",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-file-invoice-dollar",
          color: "#5f9e74"
        });
      else if (event.status === "approved")
        this.timeline.push({
          status: "Application Approved",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-check",
          color: "#73ac4d"
        });
      else if (event.status === "withdrawn")
        this.timeline.push({
          status: "Withdrawn",
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-undo",
          color: "#607D8B"
        });
      else
        this.timeline.push({
          status: event.status,
          date: event.date,
          notes: event.message,
          user,
          icon: "fas fa-fw fa-paper-plane",
          color: "#258cc7"
        });
    });

  }, //beforecreate()
  data() {
    return {
      timeline: []
    };
  }
};
</script>

<style></style>
