<template>
  <Card v-for="certificate in application.details" :key="certificate.id" class="mt-2">
    <template #content>
      <div class="flex">
        <div class="flex-grow-1">
          <p class="el-h6">
          <i :class="[statusIcon]"></i> {{ certificate.certificateName }} <span class='flag ml-3 mr-2' :class="'flag-'+application.countryCode.toLowerCase()"></span>{{application.countryName}}</p>
        </div>
        <div>
          <span class="ml-4">
            <i class="fas fa-globe-americas"></i> {{ certificate.language.toUpperCase() }}</span>

          <span class="ml-4"
            ><i class="fas fa-copy"></i> {{ certificate.copies }}
            {{ Number(certificate.copies) === 1 ? "Copy" : "Copies" }}</span>

          <span class="ml-4" v-if="Number(certificate.legalization_fees)">
            <i class="fas fa-file-certificate"></i> Legalization Requested</span>
        </div>
      </div>
    </template>
  </Card>

  <card class="mt-2">
    <template #subtitle> Products | ({{ application.products.length }} items) </template>
    <template #content>
      <ul>
        <li class="el-body1 " v-for="product in application.products" :key="product">
          {{ product }}
        </li>
      </ul>
    </template>
  </card>
</template>

<script>
export default {
  name: "CFS Details",
  components: {},
  props: ["application", "statusIcon"],
  data() {
    return {};
  },

  methods: {}, //methods

  computed: {}
};
</script>
