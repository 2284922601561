<template>
  <div>
    <div id="coo">
      <div style='grid-area:chamber'>
        <p class="el-h6 py-2">Chamber *</p>
        <p class="el-body1">{{ application.coc }}</p>
        </div>
      <div style='grid-area:title'>
        <div class="flex flex-column justify-content-center w-full h-full">
          <h5 class="my-2">
            <i :class="[statusIcon]"></i>
            Certificate Of Origin #{{ application.applicationId }}</h5>
          <p class="el-button" >Status: 
            
            <span>{{application.status}}</span> </p>
        </div>
      </div>

      <div style='grid-area:seller' :class="{'el-empty-cell': !application.applicant.company }">
        <p class="el-h6 py-2">Seller</p>
        <p class="el-body1">{{ application.applicant.company }}</p>
        <p class="el-body1 break">{{ application.applicant.address }}</p>

        </div>
      <div style='grid-area:consignee' :class="{'el-empty-cell': !application.consignee  }">
        <p class="el-h6 py-2">Consignee *</p>
        <p class="el-body1 break" v-for="consignee in application.consignee" :key="consignee">{{ consignee }} </p>
        </div>

      <div style='grid-area: transType' :class="{'el-empty-cell': !application.transportType }">
        <p class="el-h6 py-2">Transport Type *</p>
        <p class="el-body1">{{ application.transportType }}</p>
      </div>

      <div style='grid-area: destCountry' :class="{'el-empty-cell': !application.countryName }">
        <p class="el-h6 py-2">Destination Country *</p>
        <p class="el-body1"><span class="flag mr-2" :class="'flag-'+application.countryCode.toLowerCase()"></span>{{application.countryName}} ({{application.countryCode}})</p>
        </div>
      <div style='grid-area: expDate' :class="{'el-empty-cell': !application.exportDate }">
        <p class="el-h6 py-2">Export Date</p>
        <p class="el-body1" >{{application.exportDate}}</p>
      </div>
      <div style='grid-area: permit' :class="{'el-empty-cell': !application.importPermit }" >
        <p class="el-h6 py-2">Import Permit Number</p>
        <p class="el-body1 break">{{application.importPermit}}</p>
      </div>
      <div style='grid-area: owner' :class="{'el-empty-cell': !application.ownerAgent }">
        <p class="el-h6 py-2">Owner or Agent</p>
        <p class="el-body1 break">{{application.ownerAgent}}</p>
      </div>

      <div style='grid-area: loadPort' :class="{'el-empty-cell': !application.loadPort }">
        <p class="el-h6 py-2">Port of Loading *</p>
        <p class="el-body1 break">{{ application.loadPort }}</p>
        </div>

      <div style='grid-area: destPort' :class="{'el-empty-cell': !application.destPort }">
        <p class="el-h6 py-2">Destination Port *</p>
        <p class="el-body1">{{ application.destPort }}</p>
        </div>

      <div style='grid-area: carrier' :class="{'el-empty-cell': !application.exportCarrier }">
        <p class="el-h6 py-2">Exporting Carrier</p>
        <p class="el-body1 break">{{application.exportCarrier}}</p>
        </div>

      <div style='grid-area: bln' :class="{'el-empty-cell': !application.blNumber }">
        <p class="el-h6 py-2">Bill of Lading / AWB</p>
        <p class="el-body1 break">{{application.blNumber}}</p>
        </div>

      <div style='grid-area: agent' :class="{'el-empty-cell': !application.forwardingAgent }">
        <p class="el-h6 py-2">Forwarding Agent</p>
        <p class="el-body1 break">{{application.forwardingAgent}}</p>
        </div>

      <div style='grid-area:buyer' :class="{'el-empty-cell': !application.buyer }">
        <p class="el-h6 py-2">Buyer</p>
        <p class="el-body1 break" v-for="buyer in application.buyer" :key="buyer">{{buyer}}<br></p>
        </div>
      <div style='grid-area:remarks' :class="{'el-empty-cell': !application.remarks }">
        <p class="el-h6 py-2">Remarks</p>
        <p class="el-body1 break">{{application.remarks}}</p>
        </div>

      <div style='grid-area:details'>
        <div class="shipmentDetail">
          <div><p class="el-h6 py-2">Description of Goods</p></div>
          <div><p class="el-h6 py-2">Weight</p></div>
          <div><p class="el-h6 py-2">Country of Origin</p></div>
        </div>

          <div class="shipmentDetail" v-for="detail in application.products" :key="detail">
          <div><p class="el-body1">{{ detail.description }}</p>

          <span v-if="detail.hsCode">
            <p class="el-caption py-2">H.S.Code: <span class="el-body1">{{ detail.hsCode }}</span></p>
          </span>

          <span v-if="detail.marks">
            <p class="el-caption py-2">Marks:<span class="el-body1">{{ detail.marks }}</span></p> 
          </span>

          <span v-if="detail.net">
            <p class="el-caption py-2">Net Weight: <span class="el-body1">{{ detail.net }}</span></p>
           </span>
          </div>

          <div>
            <p class="el-body1">{{ detail.gross }}</p>
          </div>

          <div>
            <span v-for="country in detail.origin" :key="country">
              <p class="el-body1">{{ country }}</p></span>
          </div>

      </div>
    </div>
</div>

  </div>
</template>

<script>
export default {
  name: 'CO Details',
  props: ['application', 'statusIcon'],
  data(){
    return {
    }
  },

};
</script>

<style></style>
