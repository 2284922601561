<template>
  <div class="grid py-3">
    <div class="col flex">
      <p class="el-h5">
        <i class="fas fa-check-circle el-status-approved" aria-hidden="true"></i>
        {{ profile.company }}
      </p>
    </div>
  </div>

  <Accordion :activeIndex="0">
    <AccordionTab header="Primary Information">
      <div class="grid">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Company Information</div>
        </Divider>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Address:</p>
        </div>
        <div class="col-10">
          <p v-if="profile.address">{{ profile.address }} {{ profile.address2 }}</p>
          <p v-if="!profile.address" class="error-text">
            <i class="fas fa-exclamation-triangle"></i> No Address on File
          </p>
        </div>
      </div>

      <div class="grid el-body1" v-if="profile.operationsAddress">
        <div class="col-2">
          <p class="light-text">Operations Address:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.operationsAddress }}</p>
        </div>
      </div>

      <div class="grid el-body1" v-if="profile.webSite">
        <div class="col-2">
          <p class="light-text">Website:</p>
        </div>
        <div class="col-10">
          <a href="{{ profile.webSite }}" target="_blank"
            >{{ profile.webSite }} <i class="fas fa-external-link"></i
          ></a>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.incorporationNumber">
        <div class="col-2">
          <p class="light-text">Inc. Number:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.incorporationNumber }}</p>
        </div>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Business Licence:</p>
        </div>
        <div class="col-10">
          <p class="">
            <span>{{ profile.bl }}</span
            ><br />
            <span class="el-caption">Expires {{ profile.blExp }}</span>
          </p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.ml">
        <div class="col-2">
          <p class="light-text">Manufacturers Licence:</p>
        </div>
        <div class="col-10">
          <p class="">
            <span>{{ profile.ml }}</span
            ><br />
            <span class="el-caption">Expires {{ profile.mlExp }}</span>
          </p>
        </div>
      </div>
      <div class="grid">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Chamber Membership</div>
        </Divider>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Chamber:</p>
        </div>
        <div class="col-10">
          <p class="">
            {{ profile.coc }}
          </p>
        </div>
      </div>
      <div class="grid el-body1" v-if="Number(profile.member)">
        <div class="col-2">
          <p class="light-text">Membership</p>
        </div>
        <div class="col-10">
          <p class="">
            {{ profile.membershipNumber }}<br />
            <span class="el-caption"> Expires {{ profile.cocExp }}</span>
          </p>
        </div>
      </div>

      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Affiliations:</p>
        </div>
        <div class="col-10">
          <Chip v-if="Number(profile.lacbffa)" label="LACBFFA" class="chip-lacbffa" />
          <Chip v-if="Number(profile.wtcm)" label="WTC Miami" class="chip-wtcm" />
          <Chip v-if="Number(profile.escoc)" label="El Segundo Chamber" class="chip-escoc" />
          <Chip v-if="Number(profile.ta)" label="California Trade Alliance" class="chip-ta" />
          <Chip
            v-if="
              !Number(profile.lacbffa) &&
                !Number(profile.wtcm) &&
                !Number(profile.escoc) &&
                !Number(profile.ta)
            "
            label="None"
            class="chip-noaffiliation"
          />
        </div>
      </div>
      <div class="grid">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Profile Details</div>
        </Divider>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Created:</p>
        </div>
        <div class="col-10">
          <p class="">
            {{ profile.dateCreated }}
          </p>
        </div>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Last Update:</p>
        </div>
        <div class="col-10">
          <p class="">
            {{ profile.lastUpdate }}
          </p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.notes">
        <div class="col-2">
          <p class="light-text">Notes:</p>
        </div>
        <div class="col-10">
          <p class="">
            {{ profile.notes }}
          </p>
        </div>
      </div>

      <div class="grid">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Options</div>
        </Divider>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Suspended:</p>
        </div>
        <div class="col-10">{{ Number(profile.isSuspended) ? "Yes" : "No" }}</div>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Billing Enabled:</p>
        </div>
        <div class="col-10">
          {{ Number(profile.hasBilling) ? "Yes" : "No" }}
        </div>
      </div>
    </AccordionTab>
    <AccordionTab header="Contacts">
      <!---Primary Contact--->
      <div class="grid">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Primary Contact</div>
        </Divider>
      </div>

      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Name:</p>
        </div>
        <div class="col-10">
          <p class="capitalize">{{ profile.firstName }} {{ profile.lastName }}</p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.title">
        <div class="col-2">
          <p class="light-text">Title:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.title }}</p>
        </div>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Email:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.email }}</p>
        </div>
      </div>
      <div class="grid el-body1">
        <div class="col-2">
          <p class="light-text">Phone:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.tel }}</p>
        </div>
      </div>
      <div class="grid" v-if="profile.firstName2">
        <Divider align="left" class="divider-feature">
          <div class="p-d-inline-flex p-ai-center">Secondary Contact</div>
        </Divider>
      </div>
      <!---Secondary Contact--->
      <div class="grid el-body1" v-if="profile.firstName2">
        <div class="col-2">
          <p class="light-text">Name:</p>
        </div>
        <div class="col-10">
          <p class="capitalize">{{ profile.firstName2 }} {{ profile.lastName2 }}</p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.title2">
        <div class="col-2">
          <p class="light-text">Title:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.title2 }}</p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.email2">
        <div class="col-2">
          <p class="light-text">Email:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.email2 }}</p>
        </div>
      </div>
      <div class="grid el-body1" v-if="profile.phone2">
        <div class="col-2">
          <p class="light-text">Phone:</p>
        </div>
        <div class="col-10">
          <p class="">{{ profile.tel2 }}</p>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab header="Documents">
      Documents
    </AccordionTab>
  </Accordion>
</template>

<script>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Divider from "primevue/divider";
import Chip from "primevue/chip";

export default {
  props: ['profile'],
  inheritAttrs: true, // This is what disables attribute inheritance
  name: "Comapany Profile",
  components: {
    Accordion,
    AccordionTab,
    Divider,
    Chip
  }
};
</script>

<style></style>
