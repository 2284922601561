<template>
  <TabView lazy>

    <TabPanel >
      <template #header>
        <i class="fas fa-info-circle mr-1"></i>
        <span>Profile</span>
      </template>
      <company-profile :profile="profile"></company-profile>
    </TabPanel>

    <TabPanel >
      <template #header>
        <i class="fas fa-paper-plane mr-1"></i>
        <span>Applications</span>
      </template>
      <company-applications :id="profile.profileId"></company-applications>
    </TabPanel>
  </TabView>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CompanyProfile from "./CompanyProfile.vue";
import CompanyApplications from "./CompanyApplications.vue";

export default {
  name: "Company Information", 
  props: ['profile'],
  components: { TabView, TabPanel, CompanyProfile, CompanyApplications, }
};
</script>
