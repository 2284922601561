<template>
  <!-- Delivery Card -->
  <Card class="mt-2">
    <template #subtitle>
      Method of Delivery
    </template>
    <template #content>
      <Divider align="left" class="divider-feature" v-if="Number(application.scan_fees)">
        <div class="p-d-inline-flex p-ai-center">
          <i class="fas fa-scanner-image"></i> Scanned copies requested.
        </div>
      </Divider>

      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">
          <span v-html="deliveryMethod()"></span>
        </div>
      </Divider>
      <div class="ml-5" v-if="application.deliveryAddress">
        <p>{{ application.deliveryAddress.full_name }}</p>
        <p>{{ application.deliveryAddress.address_1 }}</p>
        <p>{{ application.deliveryAddress.address_2 }}</p>
        <p>
          {{ application.deliveryAddress.city }}
          {{ application.deliveryAddress.state }}
          {{ application.deliveryAddress.postal_code }}
        </p>
        <p>{{ application.deliveryAddress.phone }}</p>
      </div>
    </template>
  </Card>
</template>

<script>
import Divider from "primevue/divider";

export default {
  components: { Divider },
  props: ["application"],
  methods: {
    deliveryMethod() {
      let deliveryTypes = [
        { id: -2, label: "Digital Download", icon: "fas fa-envelope-open-text", fees: 0 },
        {
          id: -1,
          label: "Pick-up from Chamber of Commerce",
          icon: "fas fa-hand-holding-box",
          fees: 0
        },
        {
          id: -3,
          label: "USPS Priority Mail with Tracking",
          icon: "fas fa-shipping-timed",
          fees: 10
        },
        { id: 0, label: "USPS First-Class Mail", icon: "fab fa-usps", fees: 0 },
        { id: 1, label: "Prepaid Label (Fedex/UPS)", icon: "fas fa-barcode", fees: 0 }
      ];
      let deliveryMethod = deliveryTypes.find(
        deliveryType => Number(this.application.deliveryType) === deliveryType.id
      );
      return `<i class="${deliveryMethod.icon}"></i> ${deliveryMethod.label}`;
    } //deliveryMethod()
  }
};
</script>

<style></style>
