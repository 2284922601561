<template>
  <table class="billing-modal m-2">
    <thead>
    <tr>
      <td><span class="light-text">Date:</span></td>
      <td> {{ date(application.approvedOn) }} </td>
    </tr>

    </thead>
    <tbody v-for="certificate in application.details" :key="certificate">
      <tr>
        <td>
          <span class="light-text">{{ certificate.certificateName }}:</span>
       
        </td>
        <td><span class="uppercase">{{ certificate.language }}</span> x
          {{ certificate.copies }} copy</td>
      </tr>

      <tr>
        <td>Certificate Fees :</td>
        <td class='text-right'>{{ currency(certificate.certificate_fees) }}</td>
      </tr>

      <tr v-if="certificate.surcharge_fees">
        <td>Surcharge :</td>
        <td class='text-right'>{{ currency(certificate.surcharge_fees) }}</td>
      </tr>

      <tr v-if="certificate.stamp_fees">
        <td>Stamping Fees</td>
        <td class='text-right'>{{ currency(certificate.stamp_fees) }}</td>
      </tr>

      <tr v-if="certificate.legalization_fees">
        <td>Legalization Fees</td>
        <td class='text-right'>{{ currency(certificate.legalization_fees) }}</td>
      </tr>

      <tr v-if="certificate.scan_fees">
        <td>Scan Fees</td>
        <td class='text-right'>{{ currency(certificate.scan_fees) }}</td>
      </tr>
    </tbody>

    <tbody v-if="application.mailing_fees">
      <tr>
        <td>Mailing Fees</td>
        <td class='text-right'>{{ currency(application.mailing_fees) }}</td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td><h6>Total</h6></td>
        <td class="text-right"><h6>{{ currency(this.invoiceTotal) }}</h6></td>
      </tr>
    </tfoot>


  </table>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: ["application"],
  data() {
    return {
      invoiceTotal: 0
    };
  },

  created() {
    this.updateTotal();
  },
  methods: {
    updateTotal() {
      this.invoiceTotal = 0;
      this.application.details.forEach(el => {
        this.invoiceTotal +=
          el.certificate_fees +
          el.surcharge_fees +
          el.stamp_fees +
          el.legalization_fees +
          el.scan_fees;
      });
      this.invoiceTotal += this.application.mailing_fees;
    }, //updateTotal()
    currency(number) {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number);
    }, //currency()

    date(date) {
      return dayjs(date).format("MMM D, YYYY");
    } //date()
  }, //methods
  watch: {
    application() {
      this.updateTotal();
    }
  } //watch
};
</script>

<style>
</style>
