<template>
  <div class="contentWrapper">
    <div class="sideListContainer">
      <div class="sideList" id="x1">
        <DataTable
          :value="applications"
          dataKey="applicationId"
          responsiveLayout="scroll"
          :scrollable="true"
          scrollHeight="flex"
          :paginator="true"
          :rows="50"
          :loading="loading"
          v-model:filters="filter"
          :globalFilterFields="['company', 'applicationId']"
          filterDisplay="menu"
          showGridlines
          class="p-datatable-sm clickable"
          :rowClass="rowClass"
          columnResizeMode="expand"
          @row-click="selectRow"
        >
          <template #loading>Loading Applications...</template>
          <template #header>
            <div class="sideTools">
              <div class="sideToolsTitle">
                <h6 class="mt-2 ml2">
                  <i class="fas fa-fw fa-paper-plane fa-sm" aria-hidden="true"></i> Applications
                </h6>
              </div>
              <div class="w-full ">
                <span class="p-input-icon-left w-full">
                  <i class="fa fa-search" />
                  <InputText
                    v-model="filter['global'].value"
                    placeholder="Search"
                    :class="'fullWidthInput'"
                    type="search"
                  />
                </span>
              </div>
              <div>
                <button
                  class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
                  type="button"
                  aria-haspopup="true"
                  aria-controls="overlay_menu">
                  <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </template>
          <Column field="applicationId" header="#" :sortable="true" :class="'id-col'"></Column>

          <Column
            field="status"
            header="Status"
            :showFilterMenu="true"
            :showFilterMatchModes="false"
            :showFilterOperator="true"
            :class="'icon-col'"
          >
            <template #filter="{filterModel}">
              &nbsp;&nbsp;
              <MultiSelect
                v-model="filterModel.value"
                :options="statuses"
                optionLabel="label"
                optionValue="value"
                placeholder="Filter Status"
                display="chip"
              ></MultiSelect>
            </template>

            <template #body="{data}">
              <Chip :label="data.certificates" :icon="statusIcon(data.status)"></Chip>
            </template>
          </Column>

          <Column field="company" header="Company" :sortable="true" :class="'company-col'">
          </Column>
        </DataTable>
      </div>
    </div>

    <div class="mainContent">
      <div class="card">
        <router-view :key="$router.path"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";
import { FilterMatchMode } from "primevue/api";
import Chip from "primevue/chip";

export default {
  components: { MultiSelect, Chip },
  data() {
    return {
      applications: null,
      profiles: null,
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.IN }
      },
      statuses: [
        { value: "draft", label: "Draft" },
        { value: "pending", label: "Pending" },
        { value: "declined", label: "Declined" },
        { value: "approved", label: "Approved" }
      ]
    };
  },

  mounted() {
    this.getData();
  }, // updated()

  Updated() {}, //updated()

  methods: {
    getData() {
      this.loading = true;
      this.axios.get("/applications").then(response => {
        this.applications = response.data;
        this.applications.forEach((application, idx, applications) => {
          applications[idx].applicationId = `${application.coc.toUpperCase()}-${application.id}`;
          applications[idx].certificates = applications[idx]["certificates"].toUpperCase();
        });
        this.loading = false;

        let id = this.$route.params.id ? this.$route.params.id : this.applications[0].id;
        console.log("got application", id);
        this.$router.push({
          name: "ApplicationDetail",
          params: { id }
        });
      });
    }, // getData()

    selectRow(e) {
      this.$router.push("/applications/" + e.data.id);
    }, //selectrow()

    rowClass(data) {
      return data.id === this.$route.params.id ? "selected" : "";
    }, //rowClass()

    statusIcon(status) {
      if (status === "approved") return "fas fa-check-circle approved-icon";
      else if (status === "declined") return "fas fa-times-circle declined-icon";
      else if (status === "draft") return "fas fa-file-edit draft-icon";
      else if (status === "pending") return "fas fa-hourglass-half pending-icon";
    }
  }, // methods
  computed: {} //computed()
};
</script>
<style></style>
