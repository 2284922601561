<template>
  <div class="grid">
    <div class="col">
      <h4>Supporting Documents</h4>
    </div>
  </div>

  <div class="grid">
    <div class="col">
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">Add Documents</div>
      </Divider>
      <!---Upload Button--->
      <div v-if="!loading">
        <label>
          <span class="span-button"><i class="fas fa-upload"></i> Upload File</span>
          <input
            type="file"
            name="document"
            id="document"
            @change="upload"
            accept="application/pdf"
          />
        </label>
      </div>

      <!---During Upload--->

      <div class="flex">
        <div class="flex-grow-1" v-if="loading">
          <ProgressBar :value="progress" :showValue="true" />
        </div>

        <div class="flex-grow-0 px-2"  v-if="loading">
          <span @click="cancelUpload" class="span-button"><i class="fa fa-times"></i> Cancel</span>
        </div>
      </div>
      <div class="grid">
        <div class="col">
          <Message v-if="hasWarning" severity="warn" :closable="false"
            >Maximum file size is 20MB</Message
          >
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col">
      <Divider align="left" class="divider-feature">
        <div class="p-d-inline-flex p-ai-center">Uploaded Documents</div>
      </Divider>
      <Splitter style="height: 300px">
        <SplitterPanel>
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center"> Archived Documents</div>
          </Divider>
          <h6 v-if="!documents.length">No archive documents found</h6>
          <ul>
            <li class="doc-list" v-for="document in documents" :key="document">
              <div class="doc-grid">
                <div>
                  <a
                    :href="
                      'https://docs.ftgs.us/archive/' + Math.floor(this.id / 1000) + '/' + document
                    "
                    target="_blank"
                    >{{ document.split("__")[1] }}</a
                  >
                </div>
                <div>
                  <span @click="removeDocument(document)"><i class="fa fa-times"></i></span>
                </div>
              </div>
            </li>
          </ul>
        </SplitterPanel>
        <SplitterPanel>
          <h4>PDFS GO HERE</h4>
        </SplitterPanel>
      </Splitter>
    </div>
  </div>
</template>

<!---SCRIPTS---->

<script>
import ProgressBar from "primevue/progressbar";
import Message from "primevue/message";
import Divider from "primevue/divider";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";

export default {
  props: ["id"],
  components: { ProgressBar, Message, Divider, Splitter, SplitterPanel },
  data() {
    return {
      progress: 0,
      hasWarning: false,
      response: {},
      sourec: null,
      documents: [],
      loading: false
    };
  }, //data()
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.axios
        .get(`/application/${this.id}/documents`)
        .then(response => (this.documents = response.data));
    }, //getData()

    upload(e) {
      //show the loading bar
      this.loading = true;
      //reset progress bar
      this.progress = 0;
      console.log("x", e.target.files[0]);
      if (Math.round(parseInt(e.target.files[0].size) / (1024 * 1024)) > 20) {
        this.hasWarning = true;
        return false;
      } else {
        this.hasWarning = false;
      }

      //create cancellation token
      const CancelToken = this.axios.CancelToken;
      this.source = CancelToken.source();

      //update upload progress
      const config = {
        cancelToken: this.source.token,
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      };

      //create data object
      const params = new FormData();

      //add file for upload
      params.append("document", e.target.files[0]);

      this.axios
        .post(`/application/${this.id}/document`, params, config)
        .then(response => {
          if (response.data.error) {
            this.$swal({
              icon: "error",
              //title: 'Oops...',
              text: "Error uploading file ",
              footer: "Error code = " + response.data.error
            });

            return;
          }
          this.documents.push(response.data.fileName);
        })
        .catch(err => {
          console.log("Upload Error", err);
        })
        .finally(() => {
          e.target.value = "";
          this.loading = false;
          this.progress = 0;
        });
    }, //upload()

    cancelUpload() {
      this.source.cancel("Operation canceled by the user.");
      alert("cancel");
    }, //canelUplaod()
    removeDocument(document) {
      console.log("remove", document);
      this.axios.delete(`/application/${this.id}/${document}`).then(response => {
        console.log("delete", response.data);
        this.documents.splice(this.documents.indexOf(document), 1);
      });
    }
  },
  watch: {
    id() {
      this.getData();
    }
  }
};
</script>

<style>

</style>
