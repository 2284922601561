<template>
  <DataTable
    :value="applications"
    dataKey="applicationId"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    :loading="loading"
    showGridlines
    class="p-datatable-sm clickable"
    v-model:filters="filter"
    :globalFilterFields="['status', 'applicationId', 'countryCode']"
  >
    <template #loading>Loading Applications</template>
    <template #header>
      <h1 class="el-h5 pb-2">Previous Applications</h1>
      <div class="w-full">
        <span class="p-input-icon-left w-full">
          <i class="fa fa-search" />
          <InputText
            placeholder="Search"
            :class="'fullWidthInput'"
            v-model="filter['global'].value"
            type="search"
          />
        </span>
      </div>
    </template>

    <Column header="#">
      <template #body="{data}">
        <span class="mr-2"><i :class="statusIcon(data.status)"></i></span
        >{{ `${data.coc}-${data.certificateType}-${data.applicationId}`.toUpperCase() }}
      </template>
    </Column>

    <Column header="Submitted">
    <template #body="{data}">
      {{date(data.submit_date)}}
    </template>
    </Column>
    <Column header="Approved">
      <template #body="{data}">
        {{ date(data.approvedOn) }}
      </template></Column
    >
    <column field="approvedBy" header="Approved By"></column>

    <Column field="countryCode" header="Country">
      <template #body="{data}">
        <span class="mr-2 flag" :class="'flag-' + data.countryCode.toLowerCase()"></span>
        {{ data.countryName }}
      </template>
    </Column>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import dayjs from "dayjs";

export default {
  props: ["id"],
  inheritAttrs: true,
  data() {
    return {
      applications: [],
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      loading: false
    };
  }, //data()

  mounted() {
    this.getData();
  }, //mounted()

  methods: {
    getData() {
      this.axios.get(`/company/${this.id}/applications`).then(response => {
        response.data;
        this.applications = response.data;
      });
    }, //getData()
    statusIcon(status) {
      if (status === "approved") return "fas fa-check-circle el-status-approved";
      else if (status === "declined") return "fas fa-times-circle el-status-declined";
      else if (status === "pending") return "fas fa-hourglass-half el-status-pending";
      else if (status === "draft") return "fas fa-file-edit el-status-draft";
      else return "";
    }, //statusIcon()
    date(date) {
      return dayjs(date).format("MMM D, YYYY");
    } //date()
  }, //methods()
  computed: {}, //computed()

  watch: {
    id() {
      this.getData();
    }
  }
};
</script>

<style></style>
